import React from 'react';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import {ProductOverviewSection,
    ProductOverviewSectionHeading,
    ProductOverviewCol,
    ProductOverviewIcon,
    ProductOverviewHeading,
    ProductOverviewCustomContainer,
    ProductOverviewDesc} from './productoverview.style'
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';

const ProductOverview = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            saas2Json {
                ProductOverview {
                    DelayConstant
                    SectionHeading
                    SectionSubHeading
                    ProductOverviewCustomContainer{
                        ProductOverviewIcon
                        ProductOverviewHeading
                        ProductOverviewDesc
                    }
                }
            }
        }
    `);
        return (
        <ProductOverviewSection id="featuresContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={JSONData.saas2Json.ProductOverview.DelayConstant}>
                            <ProductOverviewSectionHeading>{JSONData.saas2Json.ProductOverview.SectionHeading}</ProductOverviewSectionHeading>
                        </Fade>
                    </Col>
                </Row>
                <ProductOverviewCustomContainer>
                    <Row>
                    {
                        JSONData.saas2Json.ProductOverview.ProductOverviewCustomContainer.map((item,idx) => {
                        return <Col md="6" sm="12" lg="4">
                            <Fade bottom delay={(idx+1)*JSONData.saas2Json.ProductOverview.DelayConstant}>
                                <ProductOverviewCol>
                                    <ProductOverviewIcon src={item.ProductOverviewIcon} alt=""/>
                                    <ProductOverviewHeading>{item.ProductOverviewHeading}</ProductOverviewHeading>
                                    <ProductOverviewDesc>{item.ProductOverviewDesc}</ProductOverviewDesc>
                                </ProductOverviewCol>
                            </Fade>
                        </Col>
                        })
                    }
                    </Row>
                </ProductOverviewCustomContainer>
            </Container>
        </ProductOverviewSection>
    );
}

export default ProductOverview;
