import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`

    body{
        font-family: 'Lato', sans-serif;
        overflow:hidden;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        color:#424345;
    }
    a{
        font-family: 'Lato', sans-serif;
        font-size:16px;
    }
`;

export default GlobalStyle;