import React from 'react';
import {Row, Col } from '@bootstrap-styled/v4';
import {FaqSection,FaqHeadingWrapper,FaqSectionHeading,FaqContainer,
    FaqAccordianWrapper} from './faq.style'
import AccordianCustom from './AccordianCustom';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

  
const Faq =()=>{
    const JSONData  = useStaticQuery(graphql`
        query{
            saas2Json {
                FAQ {
                    DelayConstant
                    FaqSectionHeading
                    AccordianCustom{
                        title
                        description
                    }
                }
            }
        }
    `);
    return (
        <FaqSection>
            <FaqContainer>
                <FaqHeadingWrapper>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={1*JSONData.saas2Json.FAQ.DelayConstant}>
                                <FaqSectionHeading>{JSONData.saas2Json.FAQ.FaqSectionHeading}</FaqSectionHeading>
                            </Fade>
                        </Col>
                    </Row>
                </FaqHeadingWrapper>
                <Fade bottom delay={1*JSONData.saas2Json.FAQ.DelayConstant}>
                    <FaqAccordianWrapper>
                        <Row>
                            <Col md="12">
                            {
                                JSONData.saas2Json.FAQ.AccordianCustom.map((item,idx) => {
                                return <AccordianCustom 
                                    title={item.title}
                                    description= {item.description} 
                                />
                                })
                            }
                            </Col>
                        </Row>
                    </FaqAccordianWrapper>
                </Fade>
            </FaqContainer>
        </FaqSection>
    );
}
export default Faq;