import React from 'react';
import {PerformanceSection,PerformanceHeadingWrapper,PerformanceSectionHeading,
    PerformanceHolder,PerformanceCount,PerformanceDetails
} from './performance.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

const Performance =() => {
    const JSONData  = useStaticQuery(graphql`
        query{
            saas2Json {
                Performance {
                    DelayConstant
                    PerformanceSectionHeading
                    PerformanceHeadingPara
                    Row{
                        PerformanceCount
                        PerformanceDetails
                    }
                }
            }
        }
    `);
    return (
        <PerformanceSection id="performanceContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <PerformanceHeadingWrapper>
                            <Fade bottom delay={1*JSONData.saas2Json.Performance.DelayConstant}>
                                <PerformanceSectionHeading>{JSONData.saas2Json.Performance.PerformanceSectionHeading}</PerformanceSectionHeading>
                            </Fade>
                        </PerformanceHeadingWrapper>
                    </Col>
                </Row>
                <Row>
                { 
                    JSONData.saas2Json.Performance.Row.map((item,idx) => {
                    return <Col md="3" sm="6">
                        <Fade bottom delay={(idx+1)*JSONData.saas2Json.Performance.DelayConstant}>
                            <PerformanceHolder>
                                <PerformanceCount>
                                    <span>
                                        {item.PerformanceCount}
                                    </span>
                                </PerformanceCount>
                                <PerformanceDetails>
                                    {item.PerformanceDetails}
                                </PerformanceDetails>
                            </PerformanceHolder>
                        </Fade>
                    </Col>
                    })
                }
                </Row>
            </Container>
        </PerformanceSection>
    );
}
    
export default Performance;