import React from "react"
import {
  PricingSection,
  PricingHeadingWrapper,
  PricingHeading,
  PricingPlanCol,
  PricingPlanImg,
  PricingPlanHeading,
  PricingPlanPrice,
  PricingPlanLine,
  PricingPlanBtn,
  PricingPlanLightText,
  PricingPlanDarkText,
  PricingPlanColCenter,
  PricingCustomRow,
  PricingPlanBtnCenter,
  PricingCol,
} from "./pricing.style"
import { Container, Row, Col } from "@bootstrap-styled/v4"
import { useStaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"

const Pricing = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      saas2Json {
        Pricing {
          DelayConstant
          PricingHeading
          PricingPlanCol {
            PricingPlanHeading
            PricingPlanImg
            PricingPlanPrice
            PricingPlanPriceSpan
            PricingPlanDarkText {
              Text
            }
            PricingPlanLightText {
              Text
            }
            PricingPlanBtn
          }
        }
      }
    }
  `)

  return (
    <PricingSection id="pricingContainer">
      <Container>
        <Row>
          <Col md="12">
            <PricingHeadingWrapper>
              <Fade bottom delay={1 * JSONData.saas2Json.Pricing.DelayConstant}>
                <PricingHeading>
                  {JSONData.saas2Json.Pricing.PricingHeading}
                </PricingHeading>
              </Fade>
            </PricingHeadingWrapper>
          </Col>
        </Row>
        <Fade bottom delay={1 * JSONData.saas2Json.Pricing.DelayConstant}>
          <PricingCustomRow>
            <PricingCol>
              <PricingPlanCol index={0}>
                <PricingPlanHeading>
                  {
                    JSONData.saas2Json.Pricing.PricingPlanCol[0]
                      .PricingPlanHeading
                  }
                </PricingPlanHeading>
                <PricingPlanImg
                  src={
                    JSONData.saas2Json.Pricing.PricingPlanCol[0].PricingPlanImg
                  }
                  alt=""
                />
                <PricingPlanPrice>
                  {
                    JSONData.saas2Json.Pricing.PricingPlanCol[0]
                      .PricingPlanPrice
                  }
                </PricingPlanPrice>
                <PricingPlanLine />
                {JSONData.saas2Json.Pricing.PricingPlanCol[0].PricingPlanDarkText.map(
                  (itemDark, idxDark) => {
                    return (
                      <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                    )
                  }
                )}
                {JSONData.saas2Json.Pricing.PricingPlanCol[0].PricingPlanLightText.map(
                  (itemLight, idxLight) => {
                    return (
                      <PricingPlanLightText>
                        {itemLight.Text}
                      </PricingPlanLightText>
                    )
                  }
                )}
                <PricingPlanBtn href="#">
                  {JSONData.saas2Json.Pricing.PricingPlanCol[0].PricingPlanBtn}
                </PricingPlanBtn>
              </PricingPlanCol>
            </PricingCol>
            <PricingCol>
              <PricingPlanColCenter>
                <PricingPlanHeading>
                  {
                    JSONData.saas2Json.Pricing.PricingPlanCol[1]
                      .PricingPlanHeading
                  }
                </PricingPlanHeading>
                <PricingPlanImg
                  src={
                    JSONData.saas2Json.Pricing.PricingPlanCol[1].PricingPlanImg
                  }
                  alt=""
                />
                <PricingPlanPrice>
                  {
                    JSONData.saas2Json.Pricing.PricingPlanCol[1]
                      .PricingPlanPrice
                  }{" "}
                  /{" "}
                  <span>
                    {
                      JSONData.saas2Json.Pricing.PricingPlanCol[1]
                        .PricingPlanPriceSpan
                    }
                  </span>
                </PricingPlanPrice>
                <PricingPlanLine />
                {JSONData.saas2Json.Pricing.PricingPlanCol[1].PricingPlanDarkText.map(
                  (itemDark, idxDark) => {
                    return (
                      <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                    )
                  }
                )}
                {JSONData.saas2Json.Pricing.PricingPlanCol[1].PricingPlanLightText.map(
                  (itemLight, idxLight) => {
                    return (
                      <PricingPlanLightText>
                        {itemLight.Text}
                      </PricingPlanLightText>
                    )
                  }
                )}
                <PricingPlanBtnCenter href="#">
                  {JSONData.saas2Json.Pricing.PricingPlanCol[1].PricingPlanBtn}
                </PricingPlanBtnCenter>
              </PricingPlanColCenter>
            </PricingCol>
            <PricingCol>
              <PricingPlanCol index={2}>
                <PricingPlanHeading>
                  {
                    JSONData.saas2Json.Pricing.PricingPlanCol[2]
                      .PricingPlanHeading
                  }
                </PricingPlanHeading>
                <PricingPlanImg
                  src={
                    JSONData.saas2Json.Pricing.PricingPlanCol[2].PricingPlanImg
                  }
                  alt=""
                />
                <PricingPlanPrice>
                  {
                    JSONData.saas2Json.Pricing.PricingPlanCol[2]
                      .PricingPlanPrice
                  }
                </PricingPlanPrice>
                <PricingPlanLine />
                {JSONData.saas2Json.Pricing.PricingPlanCol[2].PricingPlanDarkText.map(
                  (itemDark, idxDark) => {
                    return (
                      <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                    )
                  }
                )}
                {JSONData.saas2Json.Pricing.PricingPlanCol[2].PricingPlanLightText.map(
                  (itemLight, idxLight) => {
                    return (
                      <PricingPlanLightText>
                        {itemLight.Text}
                      </PricingPlanLightText>
                    )
                  }
                )}
                <PricingPlanBtn href="#">
                  {JSONData.saas2Json.Pricing.PricingPlanCol[2].PricingPlanBtn}
                </PricingPlanBtn>
              </PricingPlanCol>
            </PricingCol>
          </PricingCustomRow>
        </Fade>
      </Container>
    </PricingSection>
  )
}

export default Pricing
