import React,{Component} from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {TestimonialsSection,TestimonialsContainer,TestimonialSectionHeading,CardOuterWrapper,Card,CardProfileLayout,
    ProfilePicLayout,ProfileNameLayout,ProfileName,Designation,
    TestimonialHeading,Testimonial,PrevImg,ImgButton,NextImg,NextPrev} from './testimonials.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import GatsImg from "gatsby-image";


class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };
        
        return(
            <TestimonialsSection id="testimonialsContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={1*this.props.saas2Json.Testimonials.DelayConstant}>
                                <TestimonialSectionHeading>{this.props.saas2Json.Testimonials.TestimonialHeading}</TestimonialSectionHeading>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                <Fade bottom delay={1*this.props.saas2Json.Testimonials.DelayConstant}>
                    <TestimonialsContainer>
                        <Slider ref={c => (this.slider = c)} {...settings}>
                        {
                                this.props.saas2Json.Testimonials.CardProfileLayout.map((item,idx) => {
                                return <CardOuterWrapper>
                                <Card>
                                    <TestimonialHeading>
                                        {item.TestimonailHeading}
                                    </TestimonialHeading>
                                    <Testimonial>
                                        {item.Testimonial}
                                    </Testimonial>

                                    <CardProfileLayout>
                                        <ProfilePicLayout>
                                            <GatsImg 
                                                fluid={item.ProfilePic.childImageSharp.fluid} 
                                                className="ProfilePic"
                                                alt=""
                                            />
                                        </ProfilePicLayout>
                                        <ProfileNameLayout>
                                            <ProfileName>
                                                {item.ProfileName}
                                            </ProfileName>
                                            <Designation>
                                                {item.Designation}
                                            </Designation>
                                        </ProfileNameLayout>
                                    </CardProfileLayout>
                                </Card>
                            </CardOuterWrapper>
                                })
                            }
                        </Slider>
                    </TestimonialsContainer>
                </Fade>
                <NextPrev>
                    <ImgButton onClick={this.previous} aria-label="Prev Button">
                        <PrevImg src={this.props.saas2Json.Testimonials.PrevImg} alt=""/>
                    </ImgButton>
                    <ImgButton onClick={this.next} aria-label="Next Button">
                        <NextImg src={this.props.saas2Json.Testimonials.NextImg} alt=""/>
                    </ImgButton>
                </NextPrev>
            </TestimonialsSection>
        );
    }
}


export default () => (
    <StaticQuery
        query={graphql`
            query {
                    saas2Json{
                        Testimonials{
                            TestimonialHeading
                            CardProfileLayout{
                                ProfilePic{
                                    childImageSharp{
                                        fluid(quality: 100){
                                        ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                                ProfileName
                                Designation
                                TestimonailHeading
                                Testimonial
                            }
                            PrevImg
                            NextImg
                        }
                    }
                }
            
        `}
        render={(data) => (
            <Testimonials saas2Json={data.saas2Json}/>
        )}
    />
  )